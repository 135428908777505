import React from "react"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { FaCheckCircle as CheckIcon } from "react-icons/fa"
import FormsService from "../service/formsservice"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./contact.scss"

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      emailError: false,
      formData: { helpArea: [] },
    }
  }

  validateEmail = (email) => {
    if (/^[a-zA-Z0-9.'_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      this.setState({ emailError: false })
      return true
    } else {
      this.setState({ emailError: true })
      return false
    }
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState((oldState) => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }
  handleChangeHelpArea = (e) => {
    const name = e.target.name

    let arrTemp = this.state.formData.helpArea
    let elementIndex = arrTemp.indexOf(name)
    if (elementIndex >= 0) {
      arrTemp.splice(elementIndex, 1)
    } else {
      arrTemp.push(name)
    }
    this.setState((oldState) => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          helpArea: arrTemp,
        },
      }
    })
  }
  handleSubmit = (e) => {
    e.preventDefault()
    if (this.validateEmail(this.state.formData.email)) {
      const form = e.target
      this.setState({ submitting: true })
      let data = JSON.stringify(this.state.formData)
      FormsService.contactForm(data)
        .then((res) => {
          if (res) {
            this.setState({ submitted: true })
          }
        })
        .catch((error) => console.log("Error: ", error))
        .finally(() => {
          this.setState({ submitting: false })
        })
    }
  }

  render() {
    const { data } = this.props
    const { submitted, submitting } = this.state
    console.log(this.state.formData)
    return (
      <Layout>
        <SEO title="Contact Us" />
        <div id="contact-outer-wrapper" className="outer-wrapperContact">
          <Container className="customContainerContact">
            <div className="inner-wrapperContact">
              <h1>Contact Us</h1>
              <p className="hidden">
                <label>
                  Don’t fill this out if you're human:{" "}
                  <input name="bot-field" />
                </label>
              </p>
              <div className="formCard">
                <form
                  name="contact"
                  action="/thanks/"
                  onSubmit={this.handleSubmit}
                  disabled={submitted}
                  id="contact-form"
                >
                  <Row>
                    <Col sm={12}>
                      <div className="form-group input-parent">
                        <label>Your full name *</label>

                        <input
                          type="text"
                          required
                          id="demo-input-fullname"
                          name="fullName"
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group input-parent">
                        <label>Your email address *</label>

                        <input
                          type="text"
                          required
                          id="demo-input-email"
                          name="email"
                          className="form-control"
                          onChange={this.handleChange}
                        />
                        {this.state.emailError && (
                          <span className="invalidEmail">Email is invalid</span>
                        )}
                      </div>
                      <div className="form-group input-parent">
                        <label>Your company name *</label>

                        <input
                          type="text"
                          required
                          name="companyName"
                          id="demo-input-companyname"
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group input-parent">
                        <label>Do you have a message for us?</label>

                        <textarea
                          name="message"
                          className="form-control"
                          id="demo-input-message"
                          onChange={this.handleChange}
                          rows={10}
                          maxLength={500}
                        />
                      </div>

                      <p className="more-questions">
                        A few more questions to help us improve your experience
                      </p>
                      <div>
                        {" "}
                        {/* className="select-section" */}
                        <div className="form-group input-parent">
                          <label htmlFor="demo-input-position">
                            Your position
                          </label>
                          <select
                            name="yourPosition"
                            id="demo-input-position"
                            onChange={this.handleChange}
                            className="form-control"
                          >
                            <option value="" disabled selected hidden />
                            {data.allPositionJson.edges.map((edge) => (
                              <option
                                key={edge.node.value}
                                value={edge.node.value}
                              >
                                {edge.node.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group input-parent">
                          <label htmlFor="demo-input-size">Company size</label>
                          <select
                            name="companySize"
                            id="demo-input-size"
                            onChange={this.handleChange}
                            className="form-control"
                          >
                            <option value="" disabled selected hidden />
                            {data.allCompanySizeJson.edges.map((edge) => (
                              <option
                                key={edge.node.value}
                                value={edge.node.value}
                              >
                                {edge.node.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group input-parent">
                          <label htmlFor="demo-input-industry">Industry</label>
                          <select
                            name="industry"
                            placeholder="Choose"
                            id="demo-input-industry"
                            onChange={this.handleChange}
                            className="form-control"
                          >
                            <option value="" disabled selected hidden />
                            {data.allIndustryJson.edges.map((edge) => (
                              <option
                                key={edge.node.value}
                                value={edge.node.value}
                              >
                                {edge.node.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group input-parent">
                          <label htmlFor="demo-input-country">Country</label>
                          <input
                            type="text"
                            name="country"
                            id="demo-input-country"
                            className="form-control"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <p>
                        Which areas in your company do you need our help with?
                        (select one or more)
                      </p>
                      <div className="form-group input--checkbox input-parent contactAreas">
                        <label className="form-group">
                          <input
                            type="checkbox"
                            id="demo-input-checkbox-cc"
                            name="corporate-culture"
                            onChange={this.handleChangeHelpArea}
                          />{" "}
                          Corporate culture
                        </label>
                        <label className="form-group">
                          <input
                            type="checkbox"
                            name="employee-engagement"
                            onChange={this.handleChangeHelpArea}
                            id="demo-input-checkbox-ee"
                          />{" "}
                          Employee engagement and satisfaction
                        </label>
                        <label className="form-group">
                          <input
                            type="checkbox"
                            name="employee-productivity"
                            onChange={this.handleChangeHelpArea}
                            id="demo-input-checkbox-ep"
                          />{" "}
                          Optimising employee productivity
                        </label>
                        <label className="form-group">
                          <input
                            type="checkbox"
                            name="managing-absence"
                            onChange={this.handleChangeHelpArea}
                            id="demo-input-checkbox-ma"
                          />{" "}
                          Managing absence
                        </label>
                        <label className="form-group">
                          <input
                            type="checkbox"
                            name="psyc-wellbeing"
                            onChange={this.handleChangeHelpArea}
                            id="demo-input-checkbox-pw"
                          />{" "}
                          Physical and/or psychological wellbeing of employees
                        </label>
                        <label className="form-group">
                          <input
                            type="checkbox"
                            name="management-style"
                            id="demo-input-checkbox-ms"
                            onChange={this.handleChangeHelpArea}
                          />{" "}
                          Management/Leadership style
                        </label>
                      </div>
                      <div>
                        {/* className="select-section" */}
                        <div className="form-group input-parent">
                          <label htmlFor="demo-how-find-us">
                            How did you find us?
                          </label>
                          <select
                            name="userSource"
                            id="demo-how-find-us"
                            onChange={this.handleChange}
                            className="form-control"
                          >
                            <option value="" disabled selected hidden />
                            {data.allHowFindJson.edges.map((edge) => (
                              <option
                                key={edge.node.value}
                                value={edge.node.value}
                              >
                                {edge.node.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center">
                    {submitted && (
                      <div className="submittedSuccess">
                        Thank you. We’ll be in touch shortly.
                      </div>
                    )}
                    <button
                      type="submit"
                      id="contact-form-submit-button"
                      disabled={submitted || submitting}
                      className="text-white"
                    >
                      {(submitted && "Submitted") ||
                        (submitting && "Submitting...") ||
                        "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    allCountryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allPositionJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allIndustryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allHowFindJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allCompanySizeJson {
      edges {
        node {
          value
          name
        }
      }
    }
  }
`
